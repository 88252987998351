<template>
  <v-row dense align="center" justify="center" v-if="show" v-bind="$attrs">
    <v-sheet class="fullwidth d-flex flex-column">
      <span class="fw-700 fs-14 mb-2 primary--text">
        Select from the following secure payment methods:
      </span>
      <v-item-group v-model="payment_method">
        <v-row
          :dense="!mdAndUp"
          align="stretch"
          justify="start"
          class="flex-wrap"
          :class="{ 'flex-column': !mdAndUp }"
        >
          <v-col :cols="mdAndUp ? 3 : 12">
            <v-item value="wallet" v-slot="{ active, toggle }">
              <v-card
                :disabled="!hasWallet"
                elevation="2"
                class="pa-2 fullwidth"
                @click="toggle"
                height="60"
                :color="
                  active || payment_method == 'wallet'
                    ? 'secondary light--text'
                    : ' primary--text'
                "
              >
                <div class="d-flex align-center justify-center flex-column">
                  <strong class="fw-900 text-center fs-20"> Wallet </strong>
                  <v-avatar width="80" height="25" tile v-if="hasWallet">
                    <v-img
                      :src="active ? settings.longLogoWhite : settings.longLogo"
                    ></v-img>
                  </v-avatar>
                  <small v-else class="error--text fw-900">Not available</small>
                </div>
              </v-card>
            </v-item>
          </v-col>
          <v-col :cols="mdAndUp ? 3 : 12">
            <v-item value="card" v-slot="{ active, toggle }">
              <v-card
                elevation="2"
                height="60"
                class="pa-2 fullwidth"
                @click="toggle"
                :color="
                  active || payment_method == 'card'
                    ? 'secondary light--text'
                    : ' primary--text'
                "
              >
                <div class="d-flex flex-column align-center justify-start">
                  <strong class="fw-900 text-center fs-20 mb-1"> Card </strong>
                  <div class="d-flex align-start justify-start">
                    <v-avatar width="10" height="15" tile>
                      <v-img
                        contain
                        :src="
                          require('@/assets/appIcons/mastercard.svg').default
                        "
                      ></v-img>
                    </v-avatar>
                    <v-avatar width="10" height="15" tile>
                      <v-img
                        contain
                        :src="require('@/assets/appIcons/visa.svg').default"
                      ></v-img>
                    </v-avatar>
                  </div>
                </div>
              </v-card>
            </v-item>
          </v-col>
          <v-col :cols="mdAndUp ? 3 : 12">
            <v-item disabled value="paypal">
              <v-card elevation="1" height="60" class="pa-2 fullwidth cursor-">
                <div
                  class="d-flex align-center justify-center flex-column secondary--text"
                >
                  <strong class="fw-900 fs-20"> Others </strong>
                  <span class="fs-10 error--text fw-700">Coming soon</span>
                </div>
              </v-card>
            </v-item>
          </v-col>
          <v-col :cols="mdAndUp ? 3 : 12">
            <v-btn
              block
              height="60"
              @click="proceed"
              depressed
              :disabled="!payment_method || disabled"
              class="text-none align-self-center px-5"
              color="primary"
            >
              Pay Now
            </v-btn>
          </v-col>
        </v-row>
      </v-item-group>
    </v-sheet>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'PaymentSelector',
  props: {
    value: { type: String, default: 'card' },
    hasWallet: { type: Boolean, default: true },
    show: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    payment_method: 'card',
    selected: 'card'
  }),
  watch: {
    value: {
      handler: function (val) {
        this.payment_method = val
      },
      immediate: true
    },
    payment_method: {
      handler: function (val) {
        this.selected = val
      },
      immediate: true
    }
  },
  methods: {
    proceed() {
      this.$emit('input', this.selected)
    }
  }
}
</script>

<style></style>
