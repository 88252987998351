<template>
  <v-card flat v-bind="$attrs">
    <v-row dense align="start" justify="center">
      <v-col
        class="order-md-1 d-flex flex-column order-sm-0 pa-5"
        cols="12"
        md="12"
        sm="12"
      >
        <v-form v-model="valid">
          <v-row dense class="mb-2">
            <label class="primary--text fw-900 fs-17"> Billing Details </label>
          </v-row>
          <v-row dense align="start" justify="start">
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">Full Name</label>
              <v-text-field
                :rules="requiredRules"
                hide-details="auto"
                outlined
                dense
                :diabled="paymentIntentHasError"
                v-model="billing_details.name"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">Street</label>
              <v-text-field
                :rules="requiredRules"
                hide-details="auto"
                outlined
                dense
                :disabled="paymentIntentHasError"
                v-model="billing_details.street"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">City</label>
              <v-text-field
                :rules="requiredRules"
                hide-details="auto"
                outlined
                class=""
                v-model="billing_details.city"
                dense
                :disabled="paymentIntentHasError"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">State</label>
              <v-text-field
                :rules="requiredRules"
                hide-details="auto"
                outlined
                class=""
                v-model="billing_details.state"
                dense
                :disabled="paymentIntentHasError"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">Postal Code</label>
              <v-text-field
                :rules="requiredRules"
                hide-details="auto"
                outlined
                dense
                :disabled="paymentIntentHasError"
                v-model="billing_details.postcode"
              ></v-text-field>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <label class="subtitle-2 primary--text">Country</label>
              <v-autocomplete
                item-text="name"
                item-value="id"
                :rules="requiredRules"
                hide-details="auto"
                outlined
                dense
                :items="countries"
                :disabled="paymentIntentHasError"
                v-model="billing_details.country"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-row dense class="mt-5">
          <label class="primary--text fw-900 fs-17">Card Details</label>
        </v-row>
        <v-row dense align="start" justify="start">
          <v-col
            cols="12"
            class="d-flex flex-column mt-5 align-start justify-start"
          >
            <div class="fullwidth" v-if="paymentIntent" v-show="!loading">
              <StripeElementCard
                ref="elementsRef"
                hide-postal-code
                :amount="paymentIntent.amount"
                :element-style="elementStyle"
                :pk="settings.stripe.pub_key"
                @token="confirmCardPayment"
                @error="hasError = $event"
              ></StripeElementCard>
            </div>
            <PageLoader class="mx-auto" v-if="loading">
              <span class="fw-500 mt-5 primary--text">
                Loading payment intent...
              </span>
            </PageLoader>
            <div
              v-if="errorMessage"
              class="
                fw-900
                d-flex
                align-center
                flex-column
                justify-center
                fullwidth
                pa-2
                error--text
              "
            >
              <span class="fs-17 my-2">Cannot proceed with payment</span>
              <span class="fs-15 my-2">{{ errorMessage }}</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex py-2 align-center justify-center primary--text"
            v-if="submitting"
          >
            <v-icon left color="primary">mdi-spin mdi-loading</v-icon>
            Processing payment... Please wait...
          </v-col>
          <v-col
            cols="12"
            class="d-flex py-5 align-center flex-column justify-center"
            v-if="paymentIntent"
          >
            <v-btn
              color="primary"
              :loading="submitting"
              :disabled="!valid || hasError || submitting"
              class="text-none pa-5"
              @click="submit"
            >
              Confirm Payment ({{ (paymentIntent.amount / 100) | money }})
            </v-btn>
            <span class="fs-12 my-2 d-flex align-center fw-900 grey--text">
              Powered by
              <v-img
                class="ml-3"
                height="20"
                width="40"
                contain
                :src="require('@/assets/appIcons/stripe-icon.svg').default"
              ></v-img>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { mapGetters } from 'vuex'
import ct from 'countries-and-timezones'
export default {
  inheritAttrs: false,
  name: 'GenericCardPaymentMethod',
  components: {
    StripeElementCard
  },
  props: {
    paymentIntent: { type: Object, required: true }
  },
  data: () => ({
    loading: false,
    token: null,
    valid: false,
    submitting: false,
    token: null,
    charge: null,
    errorMessage: null,
    hasError: null,
    elementStyle: {
      base: {
        iconColor: '#3c599e',
        color: '#3c599e',
        fontWeight: '700',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883'
        },
        '::placeholder': {
          color: '#3c599e'
        }
      },
      invalid: {
        iconColor: '#e60914',
        color: '#e60914'
      }
    },
    billing_details: {
      name: null,
      street: null,
      city: null,
      state: null,
      postcode: null,
      country: null
    }
  }),
  created() {
    this.billing_details = {
      name: this.user.fullname,
      street: this.user.location,
      city: null,
      state: null,
      postcode: null,
      country: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    countries() {
      return Object.values(ct.getAllCountries())
    },
    paymentIntentHasError() {
      return this.errorMessage ? true : false
    }
  },
  methods: {
    confirmCardPayment() {
      this.submitting = true
      let card = this.$refs.elementsRef.element
      this.$refs.elementsRef.stripe
        .confirmCardPayment(`${this.paymentIntent.client_secret}`, {
          payment_method: {
            card: card,
            billing_details: {
              name: this.billing_details.name,
              address: {
                city: this.billing_details.city,
                country: this.billing_details.country,
                state: this.billing_details.state,
                postal_code: this.billing_details.postcode,
                line1: this.billing_details.street,
                line2: null
              }
            },
            type: 'card'
          }
        })
        .then((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.appSnackbar(result.error.message, 'error')
            this.$emit('payment-success', false)
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions. By @kirby: webhook added on BE to update the invoice status
              this.appSnackbar('Successfully paid!', 'success')
              this.$emit('payment-success', result)
            }
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
    submit() {
      this.submitting = true
      this.$refs.elementsRef.submit()
    }
  }
}
</script>

<style lang="scss">
.StripeElement {
  border-color: #e0e0e0 !important;
  border-width: 2px;
}
#stripe-element-errors {
  color: red;
  padding: 5px !important;
}
</style>
