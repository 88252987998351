<template>
  <v-card flat class="payment-to" :key="$route.params.ids">
    <OverlayLoader :value="loading"></OverlayLoader>
    <v-card-title class="d-flex align-center justify-space-between">
      <h2 class="primary--text fw-600">Pay Orders</h2>
      <v-btn color="primary" icon @click="goBack">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!show_payment">
      <PaymentSelector
        class="col-md-7 col-sm-12 mx-auto"
        v-model="paymentMethod"
      >
      </PaymentSelector>
      <v-row dense align="center" justify="center" class="my-2">
        <PageLoader min-height="400" v-if="loading">
          <span class="primary--text my-3">
            Loading orders... Please wait...
          </span>
        </PageLoader>
        <OrderInvoice1
          v-if="orders.length && !loading"
          :orders="orders"
        ></OrderInvoice1>
        <Empty
          v-else-if="!loading"
          headline="No orders found!"
          min-height="500"
        ></Empty>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-row dense align="center" justify="center">
        <v-btn
          color="primary"
          class="text-none mx-auto col-md-3 col-sm-12"
          height="40"
          @click="show_payment = false"
        >
          Back
        </v-btn>
      </v-row>

      <v-row dense class="fullwidth mt-10" align="start" justify="start">
        <v-col class="order-md-1 order-sm-2" :cols="mdAndUp ? 7 : 12">
          <OrderInvoice1
            v-if="orders.length && !loading"
            :orders="orders"
          ></OrderInvoice1>
        </v-col>
        <v-col class="order-md-2 order-sm-1" :cols="mdAndUp ? 5 : 12">
          <GenericCardPaymentMethod
            v-if="paymentMethod === 'card'"
            :payment-intent="paymentIntent"
            @payment-success="setOrdersPaid"
          >
          </GenericCardPaymentMethod>
          <WalletPaymentMethod
            v-else-if="paymentMethod === 'wallet'"
            :payment-intent="paymentIntent"
            :submitting="submitting"
            @confirmed-payment="setOrdersPaid"
          ></WalletPaymentMethod>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import OverlayLoader from '@/common/Loader/OverlayLoader.vue'
import OrderInvoice1 from '@/modules/InvoiceTemplates/templates/OrderInvoice1.vue'
import GenericCardPaymentMethod from '../components/GenericCardPaymentMethod.vue'
import WalletPaymentMethod from '../components/WalletPaymentMethod.vue'
import PaymentSelector from '../components/PaymentSelector.vue'
export default {
  components: {
    OverlayLoader,
    OrderInvoice1,
    GenericCardPaymentMethod,
    WalletPaymentMethod,
    PaymentSelector
  },
  data: () => ({
    paymentIntent: null,
    paymentMethod: null,
    loading: true,
    submitting: false,
    ids: [],
    orders: [],
    show_payment: false
  }),
  watch: {
    '$route.params.ids': {
      handler: function (val) {
        if (val) {
          this.ids = val.split('-')
          this.fetchOrders()
          this.fetchPaymentIntentForOrders()
        } else {
          this.ids = []
          this.orders = []
          this.paymentIntent = null
        }
      },
      immediate: true,
      deep: true
    },
    paymentMethod: {
      handler: function (val) {
        if (val) {
          this.show_payment = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    goBack() {
      if (this.$route.query.hasOwnProperty('redirect_uri')) {
        this.$router.push({ path: this.$route.query.redirect_uri })
      } else this.$router.go(-1)
    },
    fetchPaymentIntentForOrders() {
      if (!this.ids) return
      request
        .post(`api/stripe/payment-intent/orders`, { ids: this.ids })
        .then(({ data }) => {
          this.paymentIntent = data.payment_intent
        })
    },
    setOrdersPaid() {
      if (!this.ids) return
      this.submitting = true
      request
        .put(`api/orders/paid/${this.paymentMethod}`, {
          payment_intent_id: this.paymentIntent.id
        })
        .then(({ data }) => {
          this.$router
            .push({
              name: 'payment-to-success',
              params: { type: 'orders' },
              query: { ids: this.ids.join('-') }
            })
            .catch(() => {})
        })
        .finally(() => (this.submitting = false))
    },
    fetchOrders() {
      this.loading = true
      request
        .get(`api/orders?paid=0&list=true&ids=${this.ids.join('-')}`)
        .then(({ data }) => {
          this.orders = data
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.bluegreen {
  background: #1ca6df;
}
</style>
