<template>
  <v-card
    class="mx-auto pa-2 order-invoice-1"
    min-height="500"
    flat
    v-if="orders.length"
    outlined
  >
    <v-sheet
      color="#3b5a9d"
      class="rounded-0 py-10 mb-5 px-10 d-flex flex-column"
      min-height="400"
    >
      <v-row dense align="center" justify="space-between">
        <v-col cols="8" class="d-flex flex-column align-start">
          <h1 class="fs-50 mb-2 light--text">
            IN<span class="fw-900">VOICE</span>
          </h1>
          <strong class="fw-400 light--text">
            Invoice No. # {{ invoice_id }}
          </strong>
        </v-col>
        <v-col class="d-flex flex-column align-center">
          <v-avatar size="80" tile>
            <v-img
              :lazy-src="settings.loader"
              :src="settings.squareLogoWhite"
              contain
            ></v-img>
          </v-avatar>
          <strong class="fw-700 fs-30 light--text">
            {{ settings.SiteName }}
          </strong>
        </v-col>
      </v-row>
      <v-row dense class="my-3" align="center" justify="space-between">
        <v-col class="d-flex light--text flex-column" :cols="mdAndUp ? 4 : 12">
          <strong class="fw-400">INVOICE TO</strong>
          <h1 class="fw-700 fs-30 my-2">
            {{ billedTo.fullname | ucwords }}
          </h1>
          <h3 class="fw-400 fs-17">{{ billedTo.job_title | ucwords }}</h3>
          <div class="d-flex my-3 fw-500 fs-16 pa-2 black align-center">
            <div class="bluegreen py-2 px-3">TOTAL DUE:</div>
            <div class="align-self-center mx-auto pa-2">
              {{ subtotal | money }}
            </div>
          </div>
        </v-col>
        <v-col class="d-flex align-center" :cols="mdAndUp ? 8 : 12">
          <v-sheet
            height="100"
            color="transparent"
            class="
              d-flex
              light--text
              align-stretch
              justify-space-between
              fullwidth
            "
          >
            <div class="d-flex flex-column align-start pa-3">
              <div class="d-flex mb-1">
                <v-icon color="white" left>mdi-email</v-icon>
                {{ billedTo.email }}
              </div>
              <div class="d-flex mb-1">
                <v-icon color="white" left>mdi-phone</v-icon>
                {{
                  billedTo.telephone && billedTo.telephone.e164
                    ? billedTo.telephone.e164
                    : '-'
                }}
              </div>
              <div class="d-flex mb-1">
                <v-icon color="white" left>mdi-map-marker</v-icon>
                {{ billedTo.location || '-' }}
              </div>
            </div>
            <v-divider class="white" vertical></v-divider>
            <div class="d-flex flex-column align-start justify-start pa-3">
              <div class="d-flex mb-1 flex-column align-start fullwidth">
                <span class="fw-500">Invoice Date: </span>
                <span>{{ today }}</span>
              </div>
              <div class="d-flex mb-1 flex-column align-start fullwidth">
                <span class="fw-500">Due Date: </span> <span>{{ today }}</span>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet
      min-height="300"
      color="white"
      class="item-body mr-md-5 ml-md-5 mr-sm-2 ml-sm-2 rounded-2 mb-5"
    >
      <v-simple-table class="item-table">
        <thead class="bluegreen">
          <tr>
            <th class="light--text">Items</th>
            <th class="light--text text-center" :width="mdAndUp ? 150 : 50">
              Unit Price
            </th>
            <th class="light--text text-center" :width="mdAndUp ? 150 : 50">
              Quantity
            </th>
            <th class="light--text text-center" :width="mdAndUp ? 150 : 50">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order in orders"
            :key="order.id"
            class="primary--text fw-500"
          >
            <td>
              <div class="d-flex py-5 flex-column">
                <div class="fw-700">
                  {{ order.product.title | ucwords }}
                </div>
                <div class="caption">Order No. #{{ order.id }}</div>
              </div>
            </td>
            <td class="text-center">{{ order.buy_price | money }}</td>
            <td class="text-center">1</td>
            <td class="text-center">{{ order.buy_price | money }}</td>
          </tr>
          <tr>
            <td>
              <div
                class="
                  d-flex
                  align-center
                  grey--text
                  justify-center
                  flex-column
                "
              >
                <strong class="fw-600 fs-18">Discount(s) Applied</strong>
                <span class="my-2 fw-700 fs-20">$0.00</span>
              </div>
            </td>
            <td colspan="3" class="pa-0">
              <div
                class="d-flex pa-5 pa-3 flex-column primary--text fw-600 fs-18"
              >
                <v-row align="center" justify="space-between" class="px-5">
                  <span>Subtotal:</span>
                  <span>{{ subtotal | money }}</span>
                </v-row>
                <v-row align="center" justify="space-between" class="px-5">
                  <span>Discount:</span>
                  <span>{{ discount | money }}</span>
                </v-row>
                <v-row align="center" justify="space-between" class="px-5">
                  <span>Total:</span> <span>{{ subtotal | money }}</span>
                </v-row>
              </div>
              <div
                class="
                  d-flex
                  pa-5 pa-3
                  bluegreen
                  flex-column
                  light--text
                  fw-600
                  fs-18
                "
              >
                <v-row align="center" justify="space-between" class="px-5">
                  <span>PAID:</span> <span>{{ paid | money }}</span>
                </v-row>
                <v-row align="center" justify="space-between" class="px-5">
                  <span>TOTAL DUE:</span>
                  <span> {{ subtotal | money }}</span>
                </v-row>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider />
      <v-row dense class="my-5" align="stretch" justify="space-between">
        <v-col :cols="mdAndUp ? 5 : 12" class="d-flex flex-column">
          <strong class="fw-700 fs-20 grey--text">Payment Address</strong>
          <div class="d-flex align-center justify-start mt-3">
            <div class="d-flex flex-column" v-if="billedFrom">
              <strong class="grey--text">{{ billedFrom.name }}</strong>
              <span v-if="billedFrom.address" class="grey--text">{{
                billedFrom.address
              }}</span>
              <span v-if="billedFrom.contact" class="grey--text">{{
                billedFrom.contact.e164
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col :cols="mdAndUp ? 5 : 12" class="d-flex flex-column">
          <strong class="fw-700 fs-20 grey--text"> Terms & Conditions </strong>
          <p class="caption mt-3 grey--text">None</p>
        </v-col>
      </v-row>
      <v-row dense align="center" justify="center" class="mt-5 mb-1 mx-auto">
        <div class="bluegreen" style="width: 1%; height: 10px"></div>
        <div class="white" style="width: 2%; height: 10px"></div>
        <div class="bluegreen" style="width: 3%; height: 10px"></div>
        <div class="white" style="width: 3%; height: 10px"></div>
        <div class="bluegreen" style="width: 4%; height: 10px"></div>
        <div class="white" style="width: 4%; height: 10px"></div>
        <div class="bluegreen" style="width: 82%; height: 10px"></div>
      </v-row>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
export default {
  inheritAttrs: false,
  name: 'OrderInvoice1',
  props: {
    orders: { type: Array }
  },
  data: () => ({
    invoice_id: 'TEMP-0001',
    paid: 0,
    discount: 0
  }),
  computed: {
    ...mapGetters(['user']),
    today() {
      return moment().format('ll')
    },
    subtotal() {
      if (!this.orders || !this.orders.length) return 0.0
      return _.sumBy(this.orders, (o) => {
        return o.buy_price * 1
      })
    },
    billedTo() {
      return this.orders.length ? this.orders[0].user : this.user
    },
    billedFrom() {
      return this.orders.length
        ? this.orders[0].product.company
        : this.user.company
    },
    companiesInvolved() {
      return []
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.bluegreen {
  background: #1ca6df;
}
.order-invoice-1 {
  .item-body {
    margin-top: -50px;

    .item-table {
      border-collapse: collapse;
      tr {
        border: 1px solid #e0e0e0;
      }
      td {
        border: 1px solid #e0e0e0;
      }
    }
  }
}
</style>
