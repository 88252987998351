<template>
  <v-card flat v-bind="$attrs">
    <v-row dense align="start" justify="center">
      <v-col class="order-md-1 d-flex flex-column order-sm-0 px-5" cols="12">
        <v-row
          dense
          class="mt-5 flex-nowrap"
          align="stretch"
          :class="{ 'flex-column': !mdAndUp }"
          :justify="mdAndUp ? 'space-between' : 'center'"
        >
          <v-card
            color="accent"
            height="100"
            class="
              d-flex
              col-md-4 col-sm-12
              pa-2
              flex-column
              align-center
              justify-center
            "
            outlined
            elevation="3"
          >
            <strong class="my-2 primary--text">Total Balance</strong>
            <h3 class="primary--text fw-900">{{ balance_both | money }}</h3>
          </v-card>
          <v-card
            color="warning"
            height="100"
            class="
              d-flex
              col-md-4 col-sm-12
              pa-2
              mx-1
              flex-column
              align-center
              justify-center
            "
            outlined
            elevation="3"
          >
            <strong class="my-2 primary--text">Pending Balance</strong>
            <h3 class="primary--text fw-900">{{ balance_pending | money }}</h3>
          </v-card>
          <v-card
            color="success"
            height="100"
            class="
              d-flex
              col-md-4 col-sm-12
              pa-2
              flex-column
              align-center
              justify-center
            "
            outlined
            elevation="3"
          >
            <strong class="my-2 primary--text">Available Balance</strong>
            <h3 class="primary--text fw-900">
              {{ balance_available | money }}
            </h3>
          </v-card>
        </v-row>
        <v-row dense align="start" justify="start">
          <v-col
            cols="12"
            class="d-flex flex-column mt-5 align-start justify-start"
          >
            <div class="fullwidth text-center" v-show="!loading">
              <p class="primary--text fw-500">
                Available balance will be used for fullfillment of payment, only
                if Total Balance is greater than or equal to payable amount
              </p>
            </div>
            <PageLoader class="mx-auto" v-if="loading">
              <span class="fw-500 mt-5 primary--text">
                Loading payment intent...
              </span>
            </PageLoader>
            <div
              v-if="insufficient_fund"
              class="
                fw-900
                d-flex
                align-center
                flex-column
                justify-center
                fullwidth
                pa-2
                error--text
              "
            >
              <span class="fs-17 mt-2">Cannot proceed with payment</span>
              <span class="fs-15 my-2">Insufficient funds </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex py-2 align-center justify-center primary--text"
            v-if="submitting"
          >
            <v-icon left color="primary">mdi-spin mdi-loading</v-icon>
            Processing payment... Please wait...
          </v-col>
          <v-col cols="12" class="d-flex py-5 align-center justify-center">
            <v-btn
              v-if="paymentIntent"
              color="primary"
              :loading="submitting"
              :disabled="submitting || insufficient_fund"
              class="text-none pa-5 mx-1"
              @click="confirmWalletPayment"
            >
              Confirm Payment ({{ payable_amount | money }})
            </v-btn>
            <v-btn
              color="accent"
              depressed
              :disabled="submitting"
              class="text-none pa-5 mx-1"
              @click="navigateTopUp"
            >
              Top Up Wallet
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'WalletPaymentMethod',
  props: {
    paymentIntent: { type: Object, required: true },
    submitting: { type: Boolean, default: false, required: true }
  },
  data: () => ({
    loading: false,
    valid: false
  }),
  created() {
    this.getWallet()
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('wallet', [
      'balance_both',
      'balance_pending',
      'balance_available'
    ]),
    payable_amount() {
      return this.paymentIntent ? this.paymentIntent.amount / 100 : 0.0
    },
    insufficient_fund() {
      return (
        this.balance_available < this.payable_amount ||
        this.balance_both < this.payable_amount
      )
    }
  },
  methods: {
    ...mapActions('wallet', ['getWallet']),
    confirmWalletPayment() {
      this.$emit('confirmed-payment', true)
    },
    navigateTopUp() {
      this.$router.push({
        name: 'wallet-top-up',
        query: { redirect_uri: this.$route.fullPath }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
